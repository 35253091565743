export const translations = {
	af: `msgid ""
msgstr ""
"Project-Id-Version: nextcloud-webdav-filepicker\n"
"Report-Msgid-Bugs-To: translations\\@example.com\n"
"POT-Creation-Date: 2023-09-28 10:09+0200\n"
"PO-Revision-Date: 2023-09-28 08:13\n"
"Last-Translator: \n"
"Language-Team: Afrikaans\n"
"Language: af_ZA\n"
"MIME-Version: 1.0\n"
"Content-Type: text/plain; charset=UTF-8\n"
"Content-Transfer-Encoding: 8bit\n"
"Plural-Forms: nplurals=2; plural=(n != 1);\n"
"X-Crowdin-Project: nextcloud-webdav-filepicker\n"
"X-Crowdin-Project-ID: 478579\n"
"X-Crowdin-Language: af\n"
"X-Crowdin-File: /main/translationfiles/templates/nextcloud-webdav-filepicker.pot\n"
"X-Crowdin-File-ID: 207\n"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:1
msgid "Name"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:2
msgid "Size"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:3
msgid "Modified"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:4
msgid "This folder is empty"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:5
msgid "Not connected"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:6
msgid "Password protect"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:7
msgid "Allow editing"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:8
#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:9
msgid "Expires on"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:10
msgid "Link settings"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:11
msgid "Update quota"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:12
msgid "Create new folder"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:13
msgid "New folder name"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:14
msgid "Cancel"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:15
#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:24
msgid "Ok"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:16
msgid "Deselect all"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:17
msgid "Select all"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:18
msgid "Select one or multiple files"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:19
msgid "Select a file"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:20
msgid "Save to"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:21
#, javascript-format
msgid "{size} used ({percent}% of {total})"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:22
msgid "{size} used"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:23
msgid "invalid quota"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:26
msgid "{nbSelected} selected"
msgid_plural "{nbSelected} selected"
msgstr[0] ""
msgstr[1] ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:28
msgid "Get files path"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:29
msgid "Get files link"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:30
msgid "Download files"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:31
msgid "Get save file path"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:32
msgid "Get file upload link"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:33
msgid "Upload files"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:27
msgid "Upload {nbToUpload} file to {path}"
msgid_plural "Upload {nbToUpload} files to {path}"
msgstr[0] ""
msgstr[1] ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:25
msgid "Save to {path}"
msgstr ""

`,
	ar: `msgid ""
msgstr ""
"Project-Id-Version: nextcloud-webdav-filepicker\n"
"Report-Msgid-Bugs-To: translations\\@example.com\n"
"POT-Creation-Date: 2023-09-28 10:09+0200\n"
"PO-Revision-Date: 2023-09-28 08:13\n"
"Last-Translator: \n"
"Language-Team: Arabic\n"
"Language: ar_SA\n"
"MIME-Version: 1.0\n"
"Content-Type: text/plain; charset=UTF-8\n"
"Content-Transfer-Encoding: 8bit\n"
"Plural-Forms: nplurals=6; plural=(n==0 ? 0 : n==1 ? 1 : n==2 ? 2 : n%100>=3 && n%100<=10 ? 3 : n%100>=11 && n%100<=99 ? 4 : 5);\n"
"X-Crowdin-Project: nextcloud-webdav-filepicker\n"
"X-Crowdin-Project-ID: 478579\n"
"X-Crowdin-Language: ar\n"
"X-Crowdin-File: /main/translationfiles/templates/nextcloud-webdav-filepicker.pot\n"
"X-Crowdin-File-ID: 207\n"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:1
msgid "Name"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:2
msgid "Size"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:3
msgid "Modified"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:4
msgid "This folder is empty"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:5
msgid "Not connected"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:6
msgid "Password protect"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:7
msgid "Allow editing"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:8
#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:9
msgid "Expires on"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:10
msgid "Link settings"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:11
msgid "Update quota"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:12
msgid "Create new folder"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:13
msgid "New folder name"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:14
msgid "Cancel"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:15
#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:24
msgid "Ok"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:16
msgid "Deselect all"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:17
msgid "Select all"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:18
msgid "Select one or multiple files"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:19
msgid "Select a file"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:20
msgid "Save to"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:21
#, javascript-format
msgid "{size} used ({percent}% of {total})"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:22
msgid "{size} used"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:23
msgid "invalid quota"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:26
msgid "{nbSelected} selected"
msgid_plural "{nbSelected} selected"
msgstr[0] ""
msgstr[1] ""
msgstr[2] ""
msgstr[3] ""
msgstr[4] ""
msgstr[5] ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:28
msgid "Get files path"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:29
msgid "Get files link"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:30
msgid "Download files"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:31
msgid "Get save file path"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:32
msgid "Get file upload link"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:33
msgid "Upload files"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:27
msgid "Upload {nbToUpload} file to {path}"
msgid_plural "Upload {nbToUpload} files to {path}"
msgstr[0] ""
msgstr[1] ""
msgstr[2] ""
msgstr[3] ""
msgstr[4] ""
msgstr[5] ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:25
msgid "Save to {path}"
msgstr ""

`,
	ca: `msgid ""
msgstr ""
"Project-Id-Version: nextcloud-webdav-filepicker\n"
"Report-Msgid-Bugs-To: translations\\@example.com\n"
"POT-Creation-Date: 2023-09-28 10:09+0200\n"
"PO-Revision-Date: 2023-09-28 08:13\n"
"Last-Translator: \n"
"Language-Team: Catalan\n"
"Language: ca_ES\n"
"MIME-Version: 1.0\n"
"Content-Type: text/plain; charset=UTF-8\n"
"Content-Transfer-Encoding: 8bit\n"
"Plural-Forms: nplurals=2; plural=(n != 1);\n"
"X-Crowdin-Project: nextcloud-webdav-filepicker\n"
"X-Crowdin-Project-ID: 478579\n"
"X-Crowdin-Language: ca\n"
"X-Crowdin-File: /main/translationfiles/templates/nextcloud-webdav-filepicker.pot\n"
"X-Crowdin-File-ID: 207\n"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:1
msgid "Name"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:2
msgid "Size"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:3
msgid "Modified"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:4
msgid "This folder is empty"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:5
msgid "Not connected"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:6
msgid "Password protect"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:7
msgid "Allow editing"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:8
#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:9
msgid "Expires on"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:10
msgid "Link settings"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:11
msgid "Update quota"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:12
msgid "Create new folder"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:13
msgid "New folder name"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:14
msgid "Cancel"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:15
#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:24
msgid "Ok"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:16
msgid "Deselect all"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:17
msgid "Select all"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:18
msgid "Select one or multiple files"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:19
msgid "Select a file"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:20
msgid "Save to"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:21
#, javascript-format
msgid "{size} used ({percent}% of {total})"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:22
msgid "{size} used"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:23
msgid "invalid quota"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:26
msgid "{nbSelected} selected"
msgid_plural "{nbSelected} selected"
msgstr[0] ""
msgstr[1] ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:28
msgid "Get files path"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:29
msgid "Get files link"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:30
msgid "Download files"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:31
msgid "Get save file path"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:32
msgid "Get file upload link"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:33
msgid "Upload files"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:27
msgid "Upload {nbToUpload} file to {path}"
msgid_plural "Upload {nbToUpload} files to {path}"
msgstr[0] ""
msgstr[1] ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:25
msgid "Save to {path}"
msgstr ""

`,
	cs: `msgid ""
msgstr ""
"Project-Id-Version: nextcloud-webdav-filepicker\n"
"Report-Msgid-Bugs-To: translations\\@example.com\n"
"POT-Creation-Date: 2023-09-28 10:09+0200\n"
"PO-Revision-Date: 2023-09-28 08:23\n"
"Last-Translator: \n"
"Language-Team: Czech\n"
"Language: cs_CZ\n"
"MIME-Version: 1.0\n"
"Content-Type: text/plain; charset=UTF-8\n"
"Content-Transfer-Encoding: 8bit\n"
"Plural-Forms: nplurals=4; plural=(n==1) ? 0 : (n>=2 && n<=4) ? 1 : 3;\n"
"X-Crowdin-Project: nextcloud-webdav-filepicker\n"
"X-Crowdin-Project-ID: 478579\n"
"X-Crowdin-Language: cs\n"
"X-Crowdin-File: /main/translationfiles/templates/nextcloud-webdav-filepicker.pot\n"
"X-Crowdin-File-ID: 207\n"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:1
msgid "Name"
msgstr "Název"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:2
msgid "Size"
msgstr "Velikost"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:3
msgid "Modified"
msgstr "Změněno"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:4
msgid "This folder is empty"
msgstr "Tato složka je prázdná"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:5
msgid "Not connected"
msgstr "Nepřipojeno"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:6
msgid "Password protect"
msgstr "Ochránit heslem"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:7
msgid "Allow editing"
msgstr "Povolit úpravy"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:8
#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:9
msgid "Expires on"
msgstr "Platnost končí"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:10
msgid "Link settings"
msgstr "Nastavení odkazu"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:11
msgid "Update quota"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:12
msgid "Create new folder"
msgstr "Vytvořit novou složku"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:13
msgid "New folder name"
msgstr "Název nové složky"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:14
msgid "Cancel"
msgstr "Storno"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:15
#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:24
msgid "Ok"
msgstr "OK"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:16
msgid "Deselect all"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:17
msgid "Select all"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:18
msgid "Select one or multiple files"
msgstr "Vyberte jeden nebo více souborů"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:19
msgid "Select a file"
msgstr "Vyberte soubor"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:20
msgid "Save to"
msgstr "Uložit do"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:21
#, javascript-format
msgid "{size} used ({percent}% of {total})"
msgstr "{size} použito ({percent}% z {total})"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:22
msgid "{size} used"
msgstr "{size} použito"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:23
msgid "invalid quota"
msgstr "neplatná kvóta"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:26
msgid "{nbSelected} selected"
msgid_plural "{nbSelected} selected"
msgstr[0] "{nbSelected} vybráno"
msgstr[1] "{nbSelected} vybrány"
msgstr[2] "{nbSelected} vybráno"
msgstr[3] "{nbSelected} vybrány"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:28
msgid "Get files path"
msgstr "Získat popis umístění souborů"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:29
msgid "Get files link"
msgstr "Získat odkaz na soubory"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:30
msgid "Download files"
msgstr "Stáhnout soubory"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:31
msgid "Get save file path"
msgstr "Získat popis umístění uložení souboru"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:32
msgid "Get file upload link"
msgstr "Získat odkaz pro nahrání souborů"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:33
msgid "Upload files"
msgstr "Nahrát soubory"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:27
msgid "Upload {nbToUpload} file to {path}"
msgid_plural "Upload {nbToUpload} files to {path}"
msgstr[0] "Nahrát {nbToUpload} soubor do {path}"
msgstr[1] "Nahrát {nbToUpload} soubory do {path}"
msgstr[2] "Nahrát {nbToUpload} souborů do {path}"
msgstr[3] "Nahrát {nbToUpload} soubory do {path}"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:25
msgid "Save to {path}"
msgstr "Uložit do {path}"

`,
	da: `msgid ""
msgstr ""
"Project-Id-Version: nextcloud-webdav-filepicker\n"
"Report-Msgid-Bugs-To: translations\\@example.com\n"
"POT-Creation-Date: 2023-09-28 10:09+0200\n"
"PO-Revision-Date: 2023-09-28 08:13\n"
"Last-Translator: \n"
"Language-Team: Danish\n"
"Language: da_DK\n"
"MIME-Version: 1.0\n"
"Content-Type: text/plain; charset=UTF-8\n"
"Content-Transfer-Encoding: 8bit\n"
"Plural-Forms: nplurals=2; plural=(n != 1);\n"
"X-Crowdin-Project: nextcloud-webdav-filepicker\n"
"X-Crowdin-Project-ID: 478579\n"
"X-Crowdin-Language: da\n"
"X-Crowdin-File: /main/translationfiles/templates/nextcloud-webdav-filepicker.pot\n"
"X-Crowdin-File-ID: 207\n"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:1
msgid "Name"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:2
msgid "Size"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:3
msgid "Modified"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:4
msgid "This folder is empty"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:5
msgid "Not connected"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:6
msgid "Password protect"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:7
msgid "Allow editing"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:8
#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:9
msgid "Expires on"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:10
msgid "Link settings"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:11
msgid "Update quota"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:12
msgid "Create new folder"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:13
msgid "New folder name"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:14
msgid "Cancel"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:15
#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:24
msgid "Ok"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:16
msgid "Deselect all"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:17
msgid "Select all"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:18
msgid "Select one or multiple files"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:19
msgid "Select a file"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:20
msgid "Save to"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:21
#, javascript-format
msgid "{size} used ({percent}% of {total})"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:22
msgid "{size} used"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:23
msgid "invalid quota"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:26
msgid "{nbSelected} selected"
msgid_plural "{nbSelected} selected"
msgstr[0] ""
msgstr[1] ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:28
msgid "Get files path"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:29
msgid "Get files link"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:30
msgid "Download files"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:31
msgid "Get save file path"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:32
msgid "Get file upload link"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:33
msgid "Upload files"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:27
msgid "Upload {nbToUpload} file to {path}"
msgid_plural "Upload {nbToUpload} files to {path}"
msgstr[0] ""
msgstr[1] ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:25
msgid "Save to {path}"
msgstr ""

`,
	de: `msgid ""
msgstr ""
"Project-Id-Version: nextcloud-webdav-filepicker\n"
"Report-Msgid-Bugs-To: translations\\@example.com\n"
"POT-Creation-Date: 2023-09-28 10:09+0200\n"
"PO-Revision-Date: 2023-09-28 08:23\n"
"Last-Translator: \n"
"Language-Team: German\n"
"Language: de_DE\n"
"MIME-Version: 1.0\n"
"Content-Type: text/plain; charset=UTF-8\n"
"Content-Transfer-Encoding: 8bit\n"
"Plural-Forms: nplurals=2; plural=(n != 1);\n"
"X-Crowdin-Project: nextcloud-webdav-filepicker\n"
"X-Crowdin-Project-ID: 478579\n"
"X-Crowdin-Language: de\n"
"X-Crowdin-File: /main/translationfiles/templates/nextcloud-webdav-filepicker.pot\n"
"X-Crowdin-File-ID: 207\n"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:1
msgid "Name"
msgstr "Name"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:2
msgid "Size"
msgstr "Größe"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:3
msgid "Modified"
msgstr "Geändert"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:4
msgid "This folder is empty"
msgstr "Dieses Ordner ist leer"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:5
msgid "Not connected"
msgstr "Nicht verbunden"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:6
msgid "Password protect"
msgstr "Mit Passwort schützen"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:7
msgid "Allow editing"
msgstr "Bearbeiten erlauben"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:8
#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:9
msgid "Expires on"
msgstr "Gültig bis"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:10
msgid "Link settings"
msgstr "Link-Einstellungen"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:11
msgid "Update quota"
msgstr "Quota aktualisieren"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:12
msgid "Create new folder"
msgstr "Neuen Ordner erstellen"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:13
msgid "New folder name"
msgstr "Neuer Ordner-Name"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:14
msgid "Cancel"
msgstr "Abbrechen"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:15
#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:24
msgid "Ok"
msgstr "Ok"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:16
msgid "Deselect all"
msgstr "Auswahl aufheben"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:17
msgid "Select all"
msgstr "Alles auswählen"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:18
msgid "Select one or multiple files"
msgstr "Eine oder mehrere Dateien wählen"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:19
msgid "Select a file"
msgstr "Datei wählen"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:20
msgid "Save to"
msgstr "Speichern unter"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:21
#, javascript-format
msgid "{size} used ({percent}% of {total})"
msgstr "{size} verwendet ({percent}% of {total})"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:22
msgid "{size} used"
msgstr "{size} verwendet"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:23
msgid "invalid quota"
msgstr "ungültige Quota"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:26
msgid "{nbSelected} selected"
msgid_plural "{nbSelected} selected"
msgstr[0] "{nbSelected} gewählt"
msgstr[1] "{nbSelected} gewählt"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:28
msgid "Get files path"
msgstr "Dateipfad wählen"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:29
msgid "Get files link"
msgstr "Link zu Dateien erstellen"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:30
msgid "Download files"
msgstr "Dateien herunterladen"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:31
msgid "Get save file path"
msgstr "Pfad zum Speichern wählen"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:32
msgid "Get file upload link"
msgstr "Link zum Hochladen von Dateien erstellen"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:33
msgid "Upload files"
msgstr "Dateien hochladen"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:27
msgid "Upload {nbToUpload} file to {path}"
msgid_plural "Upload {nbToUpload} files to {path}"
msgstr[0] "{nbToUpload} Datei nach {path} hochladen"
msgstr[1] "{nbToUpload} Dateien nach {path} hochladen"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:25
msgid "Save to {path}"
msgstr "Speichern unter {path}"

`,
	el: `msgid ""
msgstr ""
"Project-Id-Version: nextcloud-webdav-filepicker\n"
"Report-Msgid-Bugs-To: translations\\@example.com\n"
"POT-Creation-Date: 2023-09-28 10:09+0200\n"
"PO-Revision-Date: 2023-09-28 08:13\n"
"Last-Translator: \n"
"Language-Team: Greek\n"
"Language: el_GR\n"
"MIME-Version: 1.0\n"
"Content-Type: text/plain; charset=UTF-8\n"
"Content-Transfer-Encoding: 8bit\n"
"Plural-Forms: nplurals=2; plural=(n != 1);\n"
"X-Crowdin-Project: nextcloud-webdav-filepicker\n"
"X-Crowdin-Project-ID: 478579\n"
"X-Crowdin-Language: el\n"
"X-Crowdin-File: /main/translationfiles/templates/nextcloud-webdav-filepicker.pot\n"
"X-Crowdin-File-ID: 207\n"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:1
msgid "Name"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:2
msgid "Size"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:3
msgid "Modified"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:4
msgid "This folder is empty"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:5
msgid "Not connected"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:6
msgid "Password protect"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:7
msgid "Allow editing"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:8
#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:9
msgid "Expires on"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:10
msgid "Link settings"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:11
msgid "Update quota"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:12
msgid "Create new folder"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:13
msgid "New folder name"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:14
msgid "Cancel"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:15
#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:24
msgid "Ok"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:16
msgid "Deselect all"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:17
msgid "Select all"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:18
msgid "Select one or multiple files"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:19
msgid "Select a file"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:20
msgid "Save to"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:21
#, javascript-format
msgid "{size} used ({percent}% of {total})"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:22
msgid "{size} used"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:23
msgid "invalid quota"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:26
msgid "{nbSelected} selected"
msgid_plural "{nbSelected} selected"
msgstr[0] ""
msgstr[1] ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:28
msgid "Get files path"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:29
msgid "Get files link"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:30
msgid "Download files"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:31
msgid "Get save file path"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:32
msgid "Get file upload link"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:33
msgid "Upload files"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:27
msgid "Upload {nbToUpload} file to {path}"
msgid_plural "Upload {nbToUpload} files to {path}"
msgstr[0] ""
msgstr[1] ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:25
msgid "Save to {path}"
msgstr ""

`,
	es: `msgid ""
msgstr ""
"Project-Id-Version: nextcloud-webdav-filepicker\n"
"Report-Msgid-Bugs-To: translations\\@example.com\n"
"POT-Creation-Date: 2023-09-28 10:09+0200\n"
"PO-Revision-Date: 2023-09-28 08:13\n"
"Last-Translator: \n"
"Language-Team: Spanish\n"
"Language: es_ES\n"
"MIME-Version: 1.0\n"
"Content-Type: text/plain; charset=UTF-8\n"
"Content-Transfer-Encoding: 8bit\n"
"Plural-Forms: nplurals=2; plural=(n != 1);\n"
"X-Crowdin-Project: nextcloud-webdav-filepicker\n"
"X-Crowdin-Project-ID: 478579\n"
"X-Crowdin-Language: es-ES\n"
"X-Crowdin-File: /main/translationfiles/templates/nextcloud-webdav-filepicker.pot\n"
"X-Crowdin-File-ID: 207\n"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:1
msgid "Name"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:2
msgid "Size"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:3
msgid "Modified"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:4
msgid "This folder is empty"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:5
msgid "Not connected"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:6
msgid "Password protect"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:7
msgid "Allow editing"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:8
#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:9
msgid "Expires on"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:10
msgid "Link settings"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:11
msgid "Update quota"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:12
msgid "Create new folder"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:13
msgid "New folder name"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:14
msgid "Cancel"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:15
#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:24
msgid "Ok"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:16
msgid "Deselect all"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:17
msgid "Select all"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:18
msgid "Select one or multiple files"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:19
msgid "Select a file"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:20
msgid "Save to"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:21
#, javascript-format
msgid "{size} used ({percent}% of {total})"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:22
msgid "{size} used"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:23
msgid "invalid quota"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:26
msgid "{nbSelected} selected"
msgid_plural "{nbSelected} selected"
msgstr[0] ""
msgstr[1] ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:28
msgid "Get files path"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:29
msgid "Get files link"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:30
msgid "Download files"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:31
msgid "Get save file path"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:32
msgid "Get file upload link"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:33
msgid "Upload files"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:27
msgid "Upload {nbToUpload} file to {path}"
msgid_plural "Upload {nbToUpload} files to {path}"
msgstr[0] ""
msgstr[1] ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:25
msgid "Save to {path}"
msgstr ""

`,
	fi: `msgid ""
msgstr ""
"Project-Id-Version: nextcloud-webdav-filepicker\n"
"Report-Msgid-Bugs-To: translations\\@example.com\n"
"POT-Creation-Date: 2023-09-28 10:09+0200\n"
"PO-Revision-Date: 2023-09-28 08:13\n"
"Last-Translator: \n"
"Language-Team: Finnish\n"
"Language: fi_FI\n"
"MIME-Version: 1.0\n"
"Content-Type: text/plain; charset=UTF-8\n"
"Content-Transfer-Encoding: 8bit\n"
"Plural-Forms: nplurals=2; plural=(n != 1);\n"
"X-Crowdin-Project: nextcloud-webdav-filepicker\n"
"X-Crowdin-Project-ID: 478579\n"
"X-Crowdin-Language: fi\n"
"X-Crowdin-File: /main/translationfiles/templates/nextcloud-webdav-filepicker.pot\n"
"X-Crowdin-File-ID: 207\n"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:1
msgid "Name"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:2
msgid "Size"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:3
msgid "Modified"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:4
msgid "This folder is empty"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:5
msgid "Not connected"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:6
msgid "Password protect"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:7
msgid "Allow editing"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:8
#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:9
msgid "Expires on"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:10
msgid "Link settings"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:11
msgid "Update quota"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:12
msgid "Create new folder"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:13
msgid "New folder name"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:14
msgid "Cancel"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:15
#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:24
msgid "Ok"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:16
msgid "Deselect all"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:17
msgid "Select all"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:18
msgid "Select one or multiple files"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:19
msgid "Select a file"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:20
msgid "Save to"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:21
#, javascript-format
msgid "{size} used ({percent}% of {total})"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:22
msgid "{size} used"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:23
msgid "invalid quota"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:26
msgid "{nbSelected} selected"
msgid_plural "{nbSelected} selected"
msgstr[0] ""
msgstr[1] ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:28
msgid "Get files path"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:29
msgid "Get files link"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:30
msgid "Download files"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:31
msgid "Get save file path"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:32
msgid "Get file upload link"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:33
msgid "Upload files"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:27
msgid "Upload {nbToUpload} file to {path}"
msgid_plural "Upload {nbToUpload} files to {path}"
msgstr[0] ""
msgstr[1] ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:25
msgid "Save to {path}"
msgstr ""

`,
	fr: `msgid ""
msgstr ""
"Project-Id-Version: nextcloud-webdav-filepicker\n"
"Report-Msgid-Bugs-To: translations\\@example.com\n"
"POT-Creation-Date: 2023-09-28 10:09+0200\n"
"PO-Revision-Date: 2023-09-28 08:23\n"
"Last-Translator: \n"
"Language-Team: French\n"
"Language: fr_FR\n"
"MIME-Version: 1.0\n"
"Content-Type: text/plain; charset=UTF-8\n"
"Content-Transfer-Encoding: 8bit\n"
"Plural-Forms: nplurals=2; plural=(n > 1);\n"
"X-Crowdin-Project: nextcloud-webdav-filepicker\n"
"X-Crowdin-Project-ID: 478579\n"
"X-Crowdin-Language: fr\n"
"X-Crowdin-File: /main/translationfiles/templates/nextcloud-webdav-filepicker.pot\n"
"X-Crowdin-File-ID: 207\n"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:1
msgid "Name"
msgstr "Nom"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:2
msgid "Size"
msgstr "Taille"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:3
msgid "Modified"
msgstr "Modifié"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:4
msgid "This folder is empty"
msgstr "Ce dossier est vide"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:5
msgid "Not connected"
msgstr "Non connecté"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:6
msgid "Password protect"
msgstr "Protéger par un mot de passe"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:7
msgid "Allow editing"
msgstr "Autoriser la modification"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:8
#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:9
msgid "Expires on"
msgstr "Expire le"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:10
msgid "Link settings"
msgstr "Paramètres du lien"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:11
msgid "Update quota"
msgstr "Mettre à jour le quota"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:12
msgid "Create new folder"
msgstr "Créer un nouveau dossier"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:13
msgid "New folder name"
msgstr "Nom du nouveau dossier"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:14
msgid "Cancel"
msgstr "Annuler"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:15
#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:24
msgid "Ok"
msgstr "OK"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:16
msgid "Deselect all"
msgstr "Tout désélectionner"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:17
msgid "Select all"
msgstr "Tout sélectionner"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:18
msgid "Select one or multiple files"
msgstr "Sélectionnez un ou plusieurs fichiers"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:19
msgid "Select a file"
msgstr "Sélectionnez un fichier"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:20
msgid "Save to"
msgstr "Enregistrer dans"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:21
#, javascript-format
msgid "{size} used ({percent}% of {total})"
msgstr "{size} utilisé·s ({percent} % de {total})"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:22
msgid "{size} used"
msgstr "{size} utilisé·s"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:23
msgid "invalid quota"
msgstr "quota invalide"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:26
msgid "{nbSelected} selected"
msgid_plural "{nbSelected} selected"
msgstr[0] "{nbSelected} sélectionné"
msgstr[1] "{nbSelected} sélectionnés"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:28
msgid "Get files path"
msgstr "Obtenir le chemin des fichiers"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:29
msgid "Get files link"
msgstr "Obtenir le lien vers les fichiers"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:30
msgid "Download files"
msgstr "Télécharger les fichiers"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:31
msgid "Get save file path"
msgstr "Obtenir le chemin de destination"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:32
msgid "Get file upload link"
msgstr "Obtenir un lien de téléversement"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:33
msgid "Upload files"
msgstr "Téléverser des fichiers"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:27
msgid "Upload {nbToUpload} file to {path}"
msgid_plural "Upload {nbToUpload} files to {path}"
msgstr[0] "Téléverser {nbToUpload} fichier vers {path}"
msgstr[1] "Téléverser {nbToUpload} fichiers vers {path}"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:25
msgid "Save to {path}"
msgstr "Enregistrer dans {path}"

`,
	he: `msgid ""
msgstr ""
"Project-Id-Version: nextcloud-webdav-filepicker\n"
"Report-Msgid-Bugs-To: translations\\@example.com\n"
"POT-Creation-Date: 2023-09-28 10:09+0200\n"
"PO-Revision-Date: 2023-09-28 08:13\n"
"Last-Translator: \n"
"Language-Team: Hebrew\n"
"Language: he_IL\n"
"MIME-Version: 1.0\n"
"Content-Type: text/plain; charset=UTF-8\n"
"Content-Transfer-Encoding: 8bit\n"
"Plural-Forms: nplurals=4; plural=n%100==1 ? 0 : n%100==2 ? 1 : n%100==3 || n%100==4 ? 2 : 3;\n"
"X-Crowdin-Project: nextcloud-webdav-filepicker\n"
"X-Crowdin-Project-ID: 478579\n"
"X-Crowdin-Language: he\n"
"X-Crowdin-File: /main/translationfiles/templates/nextcloud-webdav-filepicker.pot\n"
"X-Crowdin-File-ID: 207\n"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:1
msgid "Name"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:2
msgid "Size"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:3
msgid "Modified"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:4
msgid "This folder is empty"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:5
msgid "Not connected"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:6
msgid "Password protect"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:7
msgid "Allow editing"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:8
#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:9
msgid "Expires on"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:10
msgid "Link settings"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:11
msgid "Update quota"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:12
msgid "Create new folder"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:13
msgid "New folder name"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:14
msgid "Cancel"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:15
#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:24
msgid "Ok"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:16
msgid "Deselect all"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:17
msgid "Select all"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:18
msgid "Select one or multiple files"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:19
msgid "Select a file"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:20
msgid "Save to"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:21
#, javascript-format
msgid "{size} used ({percent}% of {total})"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:22
msgid "{size} used"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:23
msgid "invalid quota"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:26
msgid "{nbSelected} selected"
msgid_plural "{nbSelected} selected"
msgstr[0] ""
msgstr[1] ""
msgstr[2] ""
msgstr[3] ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:28
msgid "Get files path"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:29
msgid "Get files link"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:30
msgid "Download files"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:31
msgid "Get save file path"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:32
msgid "Get file upload link"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:33
msgid "Upload files"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:27
msgid "Upload {nbToUpload} file to {path}"
msgid_plural "Upload {nbToUpload} files to {path}"
msgstr[0] ""
msgstr[1] ""
msgstr[2] ""
msgstr[3] ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:25
msgid "Save to {path}"
msgstr ""

`,
	hu: `msgid ""
msgstr ""
"Project-Id-Version: nextcloud-webdav-filepicker\n"
"Report-Msgid-Bugs-To: translations\\@example.com\n"
"POT-Creation-Date: 2023-09-28 10:09+0200\n"
"PO-Revision-Date: 2023-09-28 08:23\n"
"Last-Translator: \n"
"Language-Team: Hungarian\n"
"Language: hu_HU\n"
"MIME-Version: 1.0\n"
"Content-Type: text/plain; charset=UTF-8\n"
"Content-Transfer-Encoding: 8bit\n"
"Plural-Forms: nplurals=2; plural=(n != 1);\n"
"X-Crowdin-Project: nextcloud-webdav-filepicker\n"
"X-Crowdin-Project-ID: 478579\n"
"X-Crowdin-Language: hu\n"
"X-Crowdin-File: /main/translationfiles/templates/nextcloud-webdav-filepicker.pot\n"
"X-Crowdin-File-ID: 207\n"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:1
msgid "Name"
msgstr "Név"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:2
msgid "Size"
msgstr "Méret"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:3
msgid "Modified"
msgstr "Módosítva"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:4
msgid "This folder is empty"
msgstr "Ez a mappa üres"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:5
msgid "Not connected"
msgstr "Nincs kapcsolat"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:6
msgid "Password protect"
msgstr "Védelem jelszóval"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:7
msgid "Allow editing"
msgstr "Szerkesztés engedélyezése"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:8
#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:9
msgid "Expires on"
msgstr "Lejár"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:10
msgid "Link settings"
msgstr "Link beállítások"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:11
msgid "Update quota"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:12
msgid "Create new folder"
msgstr "Új könyvtár készítése"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:13
msgid "New folder name"
msgstr "Új könyvtár neve"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:14
msgid "Cancel"
msgstr "Mégsem"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:15
#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:24
msgid "Ok"
msgstr "Ok"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:16
msgid "Deselect all"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:17
msgid "Select all"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:18
msgid "Select one or multiple files"
msgstr "Válassz ki egy vagy több elemet"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:19
msgid "Select a file"
msgstr "Fájl kiválasztása"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:20
msgid "Save to"
msgstr "Mentés ide"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:21
#, javascript-format
msgid "{size} used ({percent}% of {total})"
msgstr "{size} felhasználva ({total} {percent}% -a)"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:22
msgid "{size} used"
msgstr "{size} felhasználva"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:23
msgid "invalid quota"
msgstr "érvénytelen kvóta"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:26
msgid "{nbSelected} selected"
msgid_plural "{nbSelected} selected"
msgstr[0] "{nbSelected} kiválasztva"
msgstr[1] "{nbSelected} kiválasztva"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:28
msgid "Get files path"
msgstr "Fájlok elérési útja"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:29
msgid "Get files link"
msgstr "Fájlok linkje"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:30
msgid "Download files"
msgstr "Fájlok letöltése"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:31
msgid "Get save file path"
msgstr "Mentési fájl elérési útja"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:32
msgid "Get file upload link"
msgstr "Fájl feltöltési link"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:33
msgid "Upload files"
msgstr "Fájlok feltöltése"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:27
msgid "Upload {nbToUpload} file to {path}"
msgid_plural "Upload {nbToUpload} files to {path}"
msgstr[0] "{nbToUpload} fájl feltöltése ide: {path}"
msgstr[1] "{nbToUpload} fájl feltöltése ide: {path}"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:25
msgid "Save to {path}"
msgstr "Mentés ide: {path}"

`,
	it: `msgid ""
msgstr ""
"Project-Id-Version: nextcloud-webdav-filepicker\n"
"Report-Msgid-Bugs-To: translations\\@example.com\n"
"POT-Creation-Date: 2023-09-28 10:09+0200\n"
"PO-Revision-Date: 2023-09-28 08:13\n"
"Last-Translator: \n"
"Language-Team: Italian\n"
"Language: it_IT\n"
"MIME-Version: 1.0\n"
"Content-Type: text/plain; charset=UTF-8\n"
"Content-Transfer-Encoding: 8bit\n"
"Plural-Forms: nplurals=2; plural=(n != 1);\n"
"X-Crowdin-Project: nextcloud-webdav-filepicker\n"
"X-Crowdin-Project-ID: 478579\n"
"X-Crowdin-Language: it\n"
"X-Crowdin-File: /main/translationfiles/templates/nextcloud-webdav-filepicker.pot\n"
"X-Crowdin-File-ID: 207\n"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:1
msgid "Name"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:2
msgid "Size"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:3
msgid "Modified"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:4
msgid "This folder is empty"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:5
msgid "Not connected"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:6
msgid "Password protect"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:7
msgid "Allow editing"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:8
#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:9
msgid "Expires on"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:10
msgid "Link settings"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:11
msgid "Update quota"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:12
msgid "Create new folder"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:13
msgid "New folder name"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:14
msgid "Cancel"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:15
#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:24
msgid "Ok"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:16
msgid "Deselect all"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:17
msgid "Select all"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:18
msgid "Select one or multiple files"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:19
msgid "Select a file"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:20
msgid "Save to"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:21
#, javascript-format
msgid "{size} used ({percent}% of {total})"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:22
msgid "{size} used"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:23
msgid "invalid quota"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:26
msgid "{nbSelected} selected"
msgid_plural "{nbSelected} selected"
msgstr[0] ""
msgstr[1] ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:28
msgid "Get files path"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:29
msgid "Get files link"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:30
msgid "Download files"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:31
msgid "Get save file path"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:32
msgid "Get file upload link"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:33
msgid "Upload files"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:27
msgid "Upload {nbToUpload} file to {path}"
msgid_plural "Upload {nbToUpload} files to {path}"
msgstr[0] ""
msgstr[1] ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:25
msgid "Save to {path}"
msgstr ""

`,
	ja: `msgid ""
msgstr ""
"Project-Id-Version: nextcloud-webdav-filepicker\n"
"Report-Msgid-Bugs-To: translations\\@example.com\n"
"POT-Creation-Date: 2023-09-28 10:09+0200\n"
"PO-Revision-Date: 2023-09-28 08:13\n"
"Last-Translator: \n"
"Language-Team: Japanese\n"
"Language: ja_JP\n"
"MIME-Version: 1.0\n"
"Content-Type: text/plain; charset=UTF-8\n"
"Content-Transfer-Encoding: 8bit\n"
"Plural-Forms: nplurals=1; plural=0;\n"
"X-Crowdin-Project: nextcloud-webdav-filepicker\n"
"X-Crowdin-Project-ID: 478579\n"
"X-Crowdin-Language: ja\n"
"X-Crowdin-File: /main/translationfiles/templates/nextcloud-webdav-filepicker.pot\n"
"X-Crowdin-File-ID: 207\n"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:1
msgid "Name"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:2
msgid "Size"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:3
msgid "Modified"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:4
msgid "This folder is empty"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:5
msgid "Not connected"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:6
msgid "Password protect"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:7
msgid "Allow editing"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:8
#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:9
msgid "Expires on"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:10
msgid "Link settings"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:11
msgid "Update quota"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:12
msgid "Create new folder"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:13
msgid "New folder name"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:14
msgid "Cancel"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:15
#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:24
msgid "Ok"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:16
msgid "Deselect all"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:17
msgid "Select all"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:18
msgid "Select one or multiple files"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:19
msgid "Select a file"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:20
msgid "Save to"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:21
#, javascript-format
msgid "{size} used ({percent}% of {total})"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:22
msgid "{size} used"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:23
msgid "invalid quota"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:26
msgid "{nbSelected} selected"
msgid_plural "{nbSelected} selected"
msgstr[0] ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:28
msgid "Get files path"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:29
msgid "Get files link"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:30
msgid "Download files"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:31
msgid "Get save file path"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:32
msgid "Get file upload link"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:33
msgid "Upload files"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:27
msgid "Upload {nbToUpload} file to {path}"
msgid_plural "Upload {nbToUpload} files to {path}"
msgstr[0] ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:25
msgid "Save to {path}"
msgstr ""

`,
	ko: `msgid ""
msgstr ""
"Project-Id-Version: nextcloud-webdav-filepicker\n"
"Report-Msgid-Bugs-To: translations\\@example.com\n"
"POT-Creation-Date: 2023-09-28 10:09+0200\n"
"PO-Revision-Date: 2023-09-28 08:13\n"
"Last-Translator: \n"
"Language-Team: Korean\n"
"Language: ko_KR\n"
"MIME-Version: 1.0\n"
"Content-Type: text/plain; charset=UTF-8\n"
"Content-Transfer-Encoding: 8bit\n"
"Plural-Forms: nplurals=1; plural=0;\n"
"X-Crowdin-Project: nextcloud-webdav-filepicker\n"
"X-Crowdin-Project-ID: 478579\n"
"X-Crowdin-Language: ko\n"
"X-Crowdin-File: /main/translationfiles/templates/nextcloud-webdav-filepicker.pot\n"
"X-Crowdin-File-ID: 207\n"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:1
msgid "Name"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:2
msgid "Size"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:3
msgid "Modified"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:4
msgid "This folder is empty"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:5
msgid "Not connected"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:6
msgid "Password protect"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:7
msgid "Allow editing"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:8
#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:9
msgid "Expires on"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:10
msgid "Link settings"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:11
msgid "Update quota"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:12
msgid "Create new folder"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:13
msgid "New folder name"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:14
msgid "Cancel"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:15
#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:24
msgid "Ok"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:16
msgid "Deselect all"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:17
msgid "Select all"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:18
msgid "Select one or multiple files"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:19
msgid "Select a file"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:20
msgid "Save to"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:21
#, javascript-format
msgid "{size} used ({percent}% of {total})"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:22
msgid "{size} used"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:23
msgid "invalid quota"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:26
msgid "{nbSelected} selected"
msgid_plural "{nbSelected} selected"
msgstr[0] ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:28
msgid "Get files path"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:29
msgid "Get files link"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:30
msgid "Download files"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:31
msgid "Get save file path"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:32
msgid "Get file upload link"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:33
msgid "Upload files"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:27
msgid "Upload {nbToUpload} file to {path}"
msgid_plural "Upload {nbToUpload} files to {path}"
msgstr[0] ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:25
msgid "Save to {path}"
msgstr ""

`,
	nl: `msgid ""
msgstr ""
"Project-Id-Version: nextcloud-webdav-filepicker\n"
"Report-Msgid-Bugs-To: translations\\@example.com\n"
"POT-Creation-Date: 2023-09-28 10:09+0200\n"
"PO-Revision-Date: 2023-09-28 08:13\n"
"Last-Translator: \n"
"Language-Team: Dutch\n"
"Language: nl_NL\n"
"MIME-Version: 1.0\n"
"Content-Type: text/plain; charset=UTF-8\n"
"Content-Transfer-Encoding: 8bit\n"
"Plural-Forms: nplurals=2; plural=(n != 1);\n"
"X-Crowdin-Project: nextcloud-webdav-filepicker\n"
"X-Crowdin-Project-ID: 478579\n"
"X-Crowdin-Language: nl\n"
"X-Crowdin-File: /main/translationfiles/templates/nextcloud-webdav-filepicker.pot\n"
"X-Crowdin-File-ID: 207\n"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:1
msgid "Name"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:2
msgid "Size"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:3
msgid "Modified"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:4
msgid "This folder is empty"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:5
msgid "Not connected"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:6
msgid "Password protect"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:7
msgid "Allow editing"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:8
#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:9
msgid "Expires on"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:10
msgid "Link settings"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:11
msgid "Update quota"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:12
msgid "Create new folder"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:13
msgid "New folder name"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:14
msgid "Cancel"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:15
#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:24
msgid "Ok"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:16
msgid "Deselect all"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:17
msgid "Select all"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:18
msgid "Select one or multiple files"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:19
msgid "Select a file"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:20
msgid "Save to"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:21
#, javascript-format
msgid "{size} used ({percent}% of {total})"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:22
msgid "{size} used"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:23
msgid "invalid quota"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:26
msgid "{nbSelected} selected"
msgid_plural "{nbSelected} selected"
msgstr[0] ""
msgstr[1] ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:28
msgid "Get files path"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:29
msgid "Get files link"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:30
msgid "Download files"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:31
msgid "Get save file path"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:32
msgid "Get file upload link"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:33
msgid "Upload files"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:27
msgid "Upload {nbToUpload} file to {path}"
msgid_plural "Upload {nbToUpload} files to {path}"
msgstr[0] ""
msgstr[1] ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:25
msgid "Save to {path}"
msgstr ""

`,
	nn: `msgid ""
msgstr ""
"Project-Id-Version: nextcloud-webdav-filepicker\n"
"Report-Msgid-Bugs-To: translations\\@example.com\n"
"POT-Creation-Date: 2023-09-28 10:09+0200\n"
"PO-Revision-Date: 2023-09-28 08:13\n"
"Last-Translator: \n"
"Language-Team: Norwegian\n"
"Language: no_NO\n"
"MIME-Version: 1.0\n"
"Content-Type: text/plain; charset=UTF-8\n"
"Content-Transfer-Encoding: 8bit\n"
"Plural-Forms: nplurals=2; plural=(n != 1);\n"
"X-Crowdin-Project: nextcloud-webdav-filepicker\n"
"X-Crowdin-Project-ID: 478579\n"
"X-Crowdin-Language: no\n"
"X-Crowdin-File: /main/translationfiles/templates/nextcloud-webdav-filepicker.pot\n"
"X-Crowdin-File-ID: 207\n"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:1
msgid "Name"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:2
msgid "Size"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:3
msgid "Modified"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:4
msgid "This folder is empty"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:5
msgid "Not connected"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:6
msgid "Password protect"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:7
msgid "Allow editing"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:8
#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:9
msgid "Expires on"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:10
msgid "Link settings"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:11
msgid "Update quota"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:12
msgid "Create new folder"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:13
msgid "New folder name"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:14
msgid "Cancel"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:15
#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:24
msgid "Ok"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:16
msgid "Deselect all"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:17
msgid "Select all"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:18
msgid "Select one or multiple files"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:19
msgid "Select a file"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:20
msgid "Save to"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:21
#, javascript-format
msgid "{size} used ({percent}% of {total})"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:22
msgid "{size} used"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:23
msgid "invalid quota"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:26
msgid "{nbSelected} selected"
msgid_plural "{nbSelected} selected"
msgstr[0] ""
msgstr[1] ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:28
msgid "Get files path"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:29
msgid "Get files link"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:30
msgid "Download files"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:31
msgid "Get save file path"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:32
msgid "Get file upload link"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:33
msgid "Upload files"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:27
msgid "Upload {nbToUpload} file to {path}"
msgid_plural "Upload {nbToUpload} files to {path}"
msgstr[0] ""
msgstr[1] ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:25
msgid "Save to {path}"
msgstr ""

`,
	pl: `msgid ""
msgstr ""
"Project-Id-Version: nextcloud-webdav-filepicker\n"
"Report-Msgid-Bugs-To: translations\\@example.com\n"
"POT-Creation-Date: 2023-09-28 10:09+0200\n"
"PO-Revision-Date: 2023-09-28 08:13\n"
"Last-Translator: \n"
"Language-Team: Polish\n"
"Language: pl_PL\n"
"MIME-Version: 1.0\n"
"Content-Type: text/plain; charset=UTF-8\n"
"Content-Transfer-Encoding: 8bit\n"
"Plural-Forms: nplurals=4; plural=(n==1 ? 0 : (n%10>=2 && n%10<=4) && (n%100<12 || n%100>14) ? 1 : n!=1 && (n%10>=0 && n%10<=1) || (n%10>=5 && n%10<=9) || (n%100>=12 && n%100<=14) ? 2 : 3);\n"
"X-Crowdin-Project: nextcloud-webdav-filepicker\n"
"X-Crowdin-Project-ID: 478579\n"
"X-Crowdin-Language: pl\n"
"X-Crowdin-File: /main/translationfiles/templates/nextcloud-webdav-filepicker.pot\n"
"X-Crowdin-File-ID: 207\n"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:1
msgid "Name"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:2
msgid "Size"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:3
msgid "Modified"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:4
msgid "This folder is empty"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:5
msgid "Not connected"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:6
msgid "Password protect"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:7
msgid "Allow editing"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:8
#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:9
msgid "Expires on"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:10
msgid "Link settings"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:11
msgid "Update quota"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:12
msgid "Create new folder"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:13
msgid "New folder name"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:14
msgid "Cancel"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:15
#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:24
msgid "Ok"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:16
msgid "Deselect all"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:17
msgid "Select all"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:18
msgid "Select one or multiple files"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:19
msgid "Select a file"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:20
msgid "Save to"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:21
#, javascript-format
msgid "{size} used ({percent}% of {total})"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:22
msgid "{size} used"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:23
msgid "invalid quota"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:26
msgid "{nbSelected} selected"
msgid_plural "{nbSelected} selected"
msgstr[0] ""
msgstr[1] ""
msgstr[2] ""
msgstr[3] ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:28
msgid "Get files path"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:29
msgid "Get files link"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:30
msgid "Download files"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:31
msgid "Get save file path"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:32
msgid "Get file upload link"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:33
msgid "Upload files"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:27
msgid "Upload {nbToUpload} file to {path}"
msgid_plural "Upload {nbToUpload} files to {path}"
msgstr[0] ""
msgstr[1] ""
msgstr[2] ""
msgstr[3] ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:25
msgid "Save to {path}"
msgstr ""

`,
	'pt-br': `msgid ""
msgstr ""
"Project-Id-Version: nextcloud-webdav-filepicker\n"
"Report-Msgid-Bugs-To: translations\\@example.com\n"
"POT-Creation-Date: 2023-09-28 10:09+0200\n"
"PO-Revision-Date: 2023-09-28 08:13\n"
"Last-Translator: \n"
"Language-Team: Portuguese, Brazilian\n"
"Language: pt_BR\n"
"MIME-Version: 1.0\n"
"Content-Type: text/plain; charset=UTF-8\n"
"Content-Transfer-Encoding: 8bit\n"
"Plural-Forms: nplurals=2; plural=(n != 1);\n"
"X-Crowdin-Project: nextcloud-webdav-filepicker\n"
"X-Crowdin-Project-ID: 478579\n"
"X-Crowdin-Language: pt-BR\n"
"X-Crowdin-File: /main/translationfiles/templates/nextcloud-webdav-filepicker.pot\n"
"X-Crowdin-File-ID: 207\n"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:1
msgid "Name"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:2
msgid "Size"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:3
msgid "Modified"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:4
msgid "This folder is empty"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:5
msgid "Not connected"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:6
msgid "Password protect"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:7
msgid "Allow editing"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:8
#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:9
msgid "Expires on"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:10
msgid "Link settings"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:11
msgid "Update quota"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:12
msgid "Create new folder"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:13
msgid "New folder name"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:14
msgid "Cancel"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:15
#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:24
msgid "Ok"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:16
msgid "Deselect all"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:17
msgid "Select all"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:18
msgid "Select one or multiple files"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:19
msgid "Select a file"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:20
msgid "Save to"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:21
#, javascript-format
msgid "{size} used ({percent}% of {total})"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:22
msgid "{size} used"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:23
msgid "invalid quota"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:26
msgid "{nbSelected} selected"
msgid_plural "{nbSelected} selected"
msgstr[0] ""
msgstr[1] ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:28
msgid "Get files path"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:29
msgid "Get files link"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:30
msgid "Download files"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:31
msgid "Get save file path"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:32
msgid "Get file upload link"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:33
msgid "Upload files"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:27
msgid "Upload {nbToUpload} file to {path}"
msgid_plural "Upload {nbToUpload} files to {path}"
msgstr[0] ""
msgstr[1] ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:25
msgid "Save to {path}"
msgstr ""

`,
	pt: `msgid ""
msgstr ""
"Project-Id-Version: nextcloud-webdav-filepicker\n"
"Report-Msgid-Bugs-To: translations\\@example.com\n"
"POT-Creation-Date: 2023-09-28 10:09+0200\n"
"PO-Revision-Date: 2023-09-28 08:13\n"
"Last-Translator: \n"
"Language-Team: Portuguese\n"
"Language: pt_PT\n"
"MIME-Version: 1.0\n"
"Content-Type: text/plain; charset=UTF-8\n"
"Content-Transfer-Encoding: 8bit\n"
"Plural-Forms: nplurals=2; plural=(n != 1);\n"
"X-Crowdin-Project: nextcloud-webdav-filepicker\n"
"X-Crowdin-Project-ID: 478579\n"
"X-Crowdin-Language: pt-PT\n"
"X-Crowdin-File: /main/translationfiles/templates/nextcloud-webdav-filepicker.pot\n"
"X-Crowdin-File-ID: 207\n"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:1
msgid "Name"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:2
msgid "Size"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:3
msgid "Modified"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:4
msgid "This folder is empty"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:5
msgid "Not connected"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:6
msgid "Password protect"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:7
msgid "Allow editing"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:8
#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:9
msgid "Expires on"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:10
msgid "Link settings"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:11
msgid "Update quota"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:12
msgid "Create new folder"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:13
msgid "New folder name"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:14
msgid "Cancel"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:15
#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:24
msgid "Ok"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:16
msgid "Deselect all"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:17
msgid "Select all"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:18
msgid "Select one or multiple files"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:19
msgid "Select a file"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:20
msgid "Save to"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:21
#, javascript-format
msgid "{size} used ({percent}% of {total})"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:22
msgid "{size} used"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:23
msgid "invalid quota"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:26
msgid "{nbSelected} selected"
msgid_plural "{nbSelected} selected"
msgstr[0] ""
msgstr[1] ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:28
msgid "Get files path"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:29
msgid "Get files link"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:30
msgid "Download files"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:31
msgid "Get save file path"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:32
msgid "Get file upload link"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:33
msgid "Upload files"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:27
msgid "Upload {nbToUpload} file to {path}"
msgid_plural "Upload {nbToUpload} files to {path}"
msgstr[0] ""
msgstr[1] ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:25
msgid "Save to {path}"
msgstr ""

`,
	ro: `msgid ""
msgstr ""
"Project-Id-Version: nextcloud-webdav-filepicker\n"
"Report-Msgid-Bugs-To: translations\\@example.com\n"
"POT-Creation-Date: 2023-09-28 10:09+0200\n"
"PO-Revision-Date: 2023-09-28 08:13\n"
"Last-Translator: \n"
"Language-Team: Romanian\n"
"Language: ro_RO\n"
"MIME-Version: 1.0\n"
"Content-Type: text/plain; charset=UTF-8\n"
"Content-Transfer-Encoding: 8bit\n"
"Plural-Forms: nplurals=3; plural=(n==1 ? 0 : (n==0 || (n%100>0 && n%100<20)) ? 1 : 2);\n"
"X-Crowdin-Project: nextcloud-webdav-filepicker\n"
"X-Crowdin-Project-ID: 478579\n"
"X-Crowdin-Language: ro\n"
"X-Crowdin-File: /main/translationfiles/templates/nextcloud-webdav-filepicker.pot\n"
"X-Crowdin-File-ID: 207\n"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:1
msgid "Name"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:2
msgid "Size"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:3
msgid "Modified"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:4
msgid "This folder is empty"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:5
msgid "Not connected"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:6
msgid "Password protect"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:7
msgid "Allow editing"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:8
#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:9
msgid "Expires on"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:10
msgid "Link settings"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:11
msgid "Update quota"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:12
msgid "Create new folder"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:13
msgid "New folder name"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:14
msgid "Cancel"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:15
#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:24
msgid "Ok"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:16
msgid "Deselect all"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:17
msgid "Select all"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:18
msgid "Select one or multiple files"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:19
msgid "Select a file"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:20
msgid "Save to"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:21
#, javascript-format
msgid "{size} used ({percent}% of {total})"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:22
msgid "{size} used"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:23
msgid "invalid quota"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:26
msgid "{nbSelected} selected"
msgid_plural "{nbSelected} selected"
msgstr[0] ""
msgstr[1] ""
msgstr[2] ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:28
msgid "Get files path"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:29
msgid "Get files link"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:30
msgid "Download files"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:31
msgid "Get save file path"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:32
msgid "Get file upload link"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:33
msgid "Upload files"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:27
msgid "Upload {nbToUpload} file to {path}"
msgid_plural "Upload {nbToUpload} files to {path}"
msgstr[0] ""
msgstr[1] ""
msgstr[2] ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:25
msgid "Save to {path}"
msgstr ""

`,
	ru: `msgid ""
msgstr ""
"Project-Id-Version: nextcloud-webdav-filepicker\n"
"Report-Msgid-Bugs-To: translations\\@example.com\n"
"POT-Creation-Date: 2023-09-28 10:09+0200\n"
"PO-Revision-Date: 2023-09-28 08:23\n"
"Last-Translator: \n"
"Language-Team: Russian\n"
"Language: ru_RU\n"
"MIME-Version: 1.0\n"
"Content-Type: text/plain; charset=UTF-8\n"
"Content-Transfer-Encoding: 8bit\n"
"Plural-Forms: nplurals=4; plural=((n%10==1 && n%100!=11) ? 0 : ((n%10 >= 2 && n%10 <=4 && (n%100 < 12 || n%100 > 14)) ? 1 : ((n%10 == 0 || (n%10 >= 5 && n%10 <=9)) || (n%100 >= 11 && n%100 <= 14)) ? 2 : 3));\n"
"X-Crowdin-Project: nextcloud-webdav-filepicker\n"
"X-Crowdin-Project-ID: 478579\n"
"X-Crowdin-Language: ru\n"
"X-Crowdin-File: /main/translationfiles/templates/nextcloud-webdav-filepicker.pot\n"
"X-Crowdin-File-ID: 207\n"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:1
msgid "Name"
msgstr "Название"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:2
msgid "Size"
msgstr "Размер"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:3
msgid "Modified"
msgstr "Изменен"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:4
msgid "This folder is empty"
msgstr "Эта папка пуста"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:5
msgid "Not connected"
msgstr "Не подключено"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:6
msgid "Password protect"
msgstr "Защита паролем"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:7
msgid "Allow editing"
msgstr "Разрешить редактирование"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:8
#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:9
msgid "Expires on"
msgstr "Срок действия истекает"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:10
msgid "Link settings"
msgstr "Настройки ссылки"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:11
msgid "Update quota"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:12
msgid "Create new folder"
msgstr "Создать новую папку"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:13
msgid "New folder name"
msgstr "Название новой папки"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:14
msgid "Cancel"
msgstr "Отмена"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:15
#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:24
msgid "Ok"
msgstr "Ок"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:16
msgid "Deselect all"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:17
msgid "Select all"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:18
msgid "Select one or multiple files"
msgstr "Выберите один или несколько файлов"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:19
msgid "Select a file"
msgstr "Выберите файл"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:20
msgid "Save to"
msgstr "Сохранить в"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:21
#, javascript-format
msgid "{size} used ({percent}% of {total})"
msgstr "{size} использовано ({percent}% из {total})"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:22
msgid "{size} used"
msgstr "{size} использовано"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:23
msgid "invalid quota"
msgstr "неверная квота"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:26
msgid "{nbSelected} selected"
msgid_plural "{nbSelected} selected"
msgstr[0] "{nbSelected} выбрано"
msgstr[1] ""
msgstr[2] ""
msgstr[3] ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:28
msgid "Get files path"
msgstr "Получить путь к файлам"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:29
msgid "Get files link"
msgstr "Получить ссылку на файлы"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:30
msgid "Download files"
msgstr "Скачивать файлы"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:31
msgid "Get save file path"
msgstr "Получить путь к файлу сохранения"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:32
msgid "Get file upload link"
msgstr "Получить ссылку для загрузки файла"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:33
msgid "Upload files"
msgstr "Загрузить файлы"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:27
msgid "Upload {nbToUpload} file to {path}"
msgid_plural "Upload {nbToUpload} files to {path}"
msgstr[0] "Загрузить файл {nbToUpload} в {path}"
msgstr[1] ""
msgstr[2] ""
msgstr[3] ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:25
msgid "Save to {path}"
msgstr "Сохранить в {path}"

`,
	sr: `msgid ""
msgstr ""
"Project-Id-Version: nextcloud-webdav-filepicker\n"
"Report-Msgid-Bugs-To: translations\\@example.com\n"
"POT-Creation-Date: 2023-09-28 10:09+0200\n"
"PO-Revision-Date: 2023-09-28 08:13\n"
"Last-Translator: \n"
"Language-Team: Serbian (Cyrillic)\n"
"Language: sr_SP\n"
"MIME-Version: 1.0\n"
"Content-Type: text/plain; charset=UTF-8\n"
"Content-Transfer-Encoding: 8bit\n"
"Plural-Forms: nplurals=3; plural=(n%10==1 && n%100!=11 ? 0 : n%10>=2 && n%10<=4 && (n%100<10 || n%100>=20) ? 1 : 2);\n"
"X-Crowdin-Project: nextcloud-webdav-filepicker\n"
"X-Crowdin-Project-ID: 478579\n"
"X-Crowdin-Language: sr\n"
"X-Crowdin-File: /main/translationfiles/templates/nextcloud-webdav-filepicker.pot\n"
"X-Crowdin-File-ID: 207\n"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:1
msgid "Name"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:2
msgid "Size"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:3
msgid "Modified"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:4
msgid "This folder is empty"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:5
msgid "Not connected"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:6
msgid "Password protect"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:7
msgid "Allow editing"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:8
#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:9
msgid "Expires on"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:10
msgid "Link settings"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:11
msgid "Update quota"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:12
msgid "Create new folder"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:13
msgid "New folder name"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:14
msgid "Cancel"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:15
#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:24
msgid "Ok"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:16
msgid "Deselect all"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:17
msgid "Select all"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:18
msgid "Select one or multiple files"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:19
msgid "Select a file"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:20
msgid "Save to"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:21
#, javascript-format
msgid "{size} used ({percent}% of {total})"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:22
msgid "{size} used"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:23
msgid "invalid quota"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:26
msgid "{nbSelected} selected"
msgid_plural "{nbSelected} selected"
msgstr[0] ""
msgstr[1] ""
msgstr[2] ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:28
msgid "Get files path"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:29
msgid "Get files link"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:30
msgid "Download files"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:31
msgid "Get save file path"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:32
msgid "Get file upload link"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:33
msgid "Upload files"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:27
msgid "Upload {nbToUpload} file to {path}"
msgid_plural "Upload {nbToUpload} files to {path}"
msgstr[0] ""
msgstr[1] ""
msgstr[2] ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:25
msgid "Save to {path}"
msgstr ""

`,
	sv: `msgid ""
msgstr ""
"Project-Id-Version: nextcloud-webdav-filepicker\n"
"Report-Msgid-Bugs-To: translations\\@example.com\n"
"POT-Creation-Date: 2023-09-28 10:09+0200\n"
"PO-Revision-Date: 2023-09-28 08:13\n"
"Last-Translator: \n"
"Language-Team: Swedish\n"
"Language: sv_SE\n"
"MIME-Version: 1.0\n"
"Content-Type: text/plain; charset=UTF-8\n"
"Content-Transfer-Encoding: 8bit\n"
"Plural-Forms: nplurals=2; plural=(n != 1);\n"
"X-Crowdin-Project: nextcloud-webdav-filepicker\n"
"X-Crowdin-Project-ID: 478579\n"
"X-Crowdin-Language: sv-SE\n"
"X-Crowdin-File: /main/translationfiles/templates/nextcloud-webdav-filepicker.pot\n"
"X-Crowdin-File-ID: 207\n"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:1
msgid "Name"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:2
msgid "Size"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:3
msgid "Modified"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:4
msgid "This folder is empty"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:5
msgid "Not connected"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:6
msgid "Password protect"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:7
msgid "Allow editing"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:8
#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:9
msgid "Expires on"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:10
msgid "Link settings"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:11
msgid "Update quota"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:12
msgid "Create new folder"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:13
msgid "New folder name"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:14
msgid "Cancel"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:15
#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:24
msgid "Ok"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:16
msgid "Deselect all"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:17
msgid "Select all"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:18
msgid "Select one or multiple files"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:19
msgid "Select a file"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:20
msgid "Save to"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:21
#, javascript-format
msgid "{size} used ({percent}% of {total})"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:22
msgid "{size} used"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:23
msgid "invalid quota"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:26
msgid "{nbSelected} selected"
msgid_plural "{nbSelected} selected"
msgstr[0] ""
msgstr[1] ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:28
msgid "Get files path"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:29
msgid "Get files link"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:30
msgid "Download files"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:31
msgid "Get save file path"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:32
msgid "Get file upload link"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:33
msgid "Upload files"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:27
msgid "Upload {nbToUpload} file to {path}"
msgid_plural "Upload {nbToUpload} files to {path}"
msgstr[0] ""
msgstr[1] ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:25
msgid "Save to {path}"
msgstr ""

`,
	tr: `msgid ""
msgstr ""
"Project-Id-Version: nextcloud-webdav-filepicker\n"
"Report-Msgid-Bugs-To: translations\\@example.com\n"
"POT-Creation-Date: 2023-09-28 10:09+0200\n"
"PO-Revision-Date: 2023-09-28 08:13\n"
"Last-Translator: \n"
"Language-Team: Turkish\n"
"Language: tr_TR\n"
"MIME-Version: 1.0\n"
"Content-Type: text/plain; charset=UTF-8\n"
"Content-Transfer-Encoding: 8bit\n"
"Plural-Forms: nplurals=2; plural=(n != 1);\n"
"X-Crowdin-Project: nextcloud-webdav-filepicker\n"
"X-Crowdin-Project-ID: 478579\n"
"X-Crowdin-Language: tr\n"
"X-Crowdin-File: /main/translationfiles/templates/nextcloud-webdav-filepicker.pot\n"
"X-Crowdin-File-ID: 207\n"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:1
msgid "Name"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:2
msgid "Size"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:3
msgid "Modified"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:4
msgid "This folder is empty"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:5
msgid "Not connected"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:6
msgid "Password protect"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:7
msgid "Allow editing"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:8
#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:9
msgid "Expires on"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:10
msgid "Link settings"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:11
msgid "Update quota"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:12
msgid "Create new folder"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:13
msgid "New folder name"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:14
msgid "Cancel"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:15
#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:24
msgid "Ok"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:16
msgid "Deselect all"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:17
msgid "Select all"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:18
msgid "Select one or multiple files"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:19
msgid "Select a file"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:20
msgid "Save to"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:21
#, javascript-format
msgid "{size} used ({percent}% of {total})"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:22
msgid "{size} used"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:23
msgid "invalid quota"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:26
msgid "{nbSelected} selected"
msgid_plural "{nbSelected} selected"
msgstr[0] ""
msgstr[1] ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:28
msgid "Get files path"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:29
msgid "Get files link"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:30
msgid "Download files"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:31
msgid "Get save file path"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:32
msgid "Get file upload link"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:33
msgid "Upload files"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:27
msgid "Upload {nbToUpload} file to {path}"
msgid_plural "Upload {nbToUpload} files to {path}"
msgstr[0] ""
msgstr[1] ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:25
msgid "Save to {path}"
msgstr ""

`,
	uk: `msgid ""
msgstr ""
"Project-Id-Version: nextcloud-webdav-filepicker\n"
"Report-Msgid-Bugs-To: translations\\@example.com\n"
"POT-Creation-Date: 2023-09-28 10:09+0200\n"
"PO-Revision-Date: 2023-09-28 08:13\n"
"Last-Translator: \n"
"Language-Team: Ukrainian\n"
"Language: uk_UA\n"
"MIME-Version: 1.0\n"
"Content-Type: text/plain; charset=UTF-8\n"
"Content-Transfer-Encoding: 8bit\n"
"Plural-Forms: nplurals=4; plural=((n%10==1 && n%100!=11) ? 0 : ((n%10 >= 2 && n%10 <=4 && (n%100 < 12 || n%100 > 14)) ? 1 : ((n%10 == 0 || (n%10 >= 5 && n%10 <=9)) || (n%100 >= 11 && n%100 <= 14)) ? 2 : 3));\n"
"X-Crowdin-Project: nextcloud-webdav-filepicker\n"
"X-Crowdin-Project-ID: 478579\n"
"X-Crowdin-Language: uk\n"
"X-Crowdin-File: /main/translationfiles/templates/nextcloud-webdav-filepicker.pot\n"
"X-Crowdin-File-ID: 207\n"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:1
msgid "Name"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:2
msgid "Size"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:3
msgid "Modified"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:4
msgid "This folder is empty"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:5
msgid "Not connected"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:6
msgid "Password protect"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:7
msgid "Allow editing"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:8
#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:9
msgid "Expires on"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:10
msgid "Link settings"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:11
msgid "Update quota"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:12
msgid "Create new folder"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:13
msgid "New folder name"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:14
msgid "Cancel"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:15
#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:24
msgid "Ok"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:16
msgid "Deselect all"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:17
msgid "Select all"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:18
msgid "Select one or multiple files"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:19
msgid "Select a file"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:20
msgid "Save to"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:21
#, javascript-format
msgid "{size} used ({percent}% of {total})"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:22
msgid "{size} used"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:23
msgid "invalid quota"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:26
msgid "{nbSelected} selected"
msgid_plural "{nbSelected} selected"
msgstr[0] ""
msgstr[1] ""
msgstr[2] ""
msgstr[3] ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:28
msgid "Get files path"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:29
msgid "Get files link"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:30
msgid "Download files"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:31
msgid "Get save file path"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:32
msgid "Get file upload link"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:33
msgid "Upload files"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:27
msgid "Upload {nbToUpload} file to {path}"
msgid_plural "Upload {nbToUpload} files to {path}"
msgstr[0] ""
msgstr[1] ""
msgstr[2] ""
msgstr[3] ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:25
msgid "Save to {path}"
msgstr ""

`,
	vi: `msgid ""
msgstr ""
"Project-Id-Version: nextcloud-webdav-filepicker\n"
"Report-Msgid-Bugs-To: translations\\@example.com\n"
"POT-Creation-Date: 2023-09-28 10:09+0200\n"
"PO-Revision-Date: 2023-09-28 08:13\n"
"Last-Translator: \n"
"Language-Team: Vietnamese\n"
"Language: vi_VN\n"
"MIME-Version: 1.0\n"
"Content-Type: text/plain; charset=UTF-8\n"
"Content-Transfer-Encoding: 8bit\n"
"Plural-Forms: nplurals=1; plural=0;\n"
"X-Crowdin-Project: nextcloud-webdav-filepicker\n"
"X-Crowdin-Project-ID: 478579\n"
"X-Crowdin-Language: vi\n"
"X-Crowdin-File: /main/translationfiles/templates/nextcloud-webdav-filepicker.pot\n"
"X-Crowdin-File-ID: 207\n"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:1
msgid "Name"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:2
msgid "Size"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:3
msgid "Modified"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:4
msgid "This folder is empty"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:5
msgid "Not connected"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:6
msgid "Password protect"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:7
msgid "Allow editing"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:8
#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:9
msgid "Expires on"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:10
msgid "Link settings"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:11
msgid "Update quota"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:12
msgid "Create new folder"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:13
msgid "New folder name"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:14
msgid "Cancel"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:15
#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:24
msgid "Ok"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:16
msgid "Deselect all"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:17
msgid "Select all"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:18
msgid "Select one or multiple files"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:19
msgid "Select a file"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:20
msgid "Save to"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:21
#, javascript-format
msgid "{size} used ({percent}% of {total})"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:22
msgid "{size} used"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:23
msgid "invalid quota"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:26
msgid "{nbSelected} selected"
msgid_plural "{nbSelected} selected"
msgstr[0] ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:28
msgid "Get files path"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:29
msgid "Get files link"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:30
msgid "Download files"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:31
msgid "Get save file path"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:32
msgid "Get file upload link"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:33
msgid "Upload files"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:27
msgid "Upload {nbToUpload} file to {path}"
msgid_plural "Upload {nbToUpload} files to {path}"
msgstr[0] ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:25
msgid "Save to {path}"
msgstr ""

`,
	'zh-hans': `msgid ""
msgstr ""
"Project-Id-Version: nextcloud-webdav-filepicker\n"
"Report-Msgid-Bugs-To: translations\\@example.com\n"
"POT-Creation-Date: 2023-09-28 10:09+0200\n"
"PO-Revision-Date: 2023-09-28 08:13\n"
"Last-Translator: \n"
"Language-Team: Chinese Simplified\n"
"Language: zh_CN\n"
"MIME-Version: 1.0\n"
"Content-Type: text/plain; charset=UTF-8\n"
"Content-Transfer-Encoding: 8bit\n"
"Plural-Forms: nplurals=1; plural=0;\n"
"X-Crowdin-Project: nextcloud-webdav-filepicker\n"
"X-Crowdin-Project-ID: 478579\n"
"X-Crowdin-Language: zh-CN\n"
"X-Crowdin-File: /main/translationfiles/templates/nextcloud-webdav-filepicker.pot\n"
"X-Crowdin-File-ID: 207\n"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:1
msgid "Name"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:2
msgid "Size"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:3
msgid "Modified"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:4
msgid "This folder is empty"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:5
msgid "Not connected"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:6
msgid "Password protect"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:7
msgid "Allow editing"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:8
#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:9
msgid "Expires on"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:10
msgid "Link settings"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:11
msgid "Update quota"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:12
msgid "Create new folder"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:13
msgid "New folder name"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:14
msgid "Cancel"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:15
#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:24
msgid "Ok"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:16
msgid "Deselect all"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:17
msgid "Select all"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:18
msgid "Select one or multiple files"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:19
msgid "Select a file"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:20
msgid "Save to"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:21
#, javascript-format
msgid "{size} used ({percent}% of {total})"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:22
msgid "{size} used"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:23
msgid "invalid quota"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:26
msgid "{nbSelected} selected"
msgid_plural "{nbSelected} selected"
msgstr[0] ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:28
msgid "Get files path"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:29
msgid "Get files link"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:30
msgid "Download files"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:31
msgid "Get save file path"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:32
msgid "Get file upload link"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:33
msgid "Upload files"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:27
msgid "Upload {nbToUpload} file to {path}"
msgid_plural "Upload {nbToUpload} files to {path}"
msgstr[0] ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:25
msgid "Save to {path}"
msgstr ""

`,
	'zh-hant': `msgid ""
msgstr ""
"Project-Id-Version: nextcloud-webdav-filepicker\n"
"Report-Msgid-Bugs-To: translations\\@example.com\n"
"POT-Creation-Date: 2023-09-28 10:09+0200\n"
"PO-Revision-Date: 2023-09-28 08:13\n"
"Last-Translator: \n"
"Language-Team: Chinese Traditional\n"
"Language: zh_TW\n"
"MIME-Version: 1.0\n"
"Content-Type: text/plain; charset=UTF-8\n"
"Content-Transfer-Encoding: 8bit\n"
"Plural-Forms: nplurals=1; plural=0;\n"
"X-Crowdin-Project: nextcloud-webdav-filepicker\n"
"X-Crowdin-Project-ID: 478579\n"
"X-Crowdin-Language: zh-TW\n"
"X-Crowdin-File: /main/translationfiles/templates/nextcloud-webdav-filepicker.pot\n"
"X-Crowdin-File-ID: 207\n"

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:1
msgid "Name"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:2
msgid "Size"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:3
msgid "Modified"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:4
msgid "This folder is empty"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:5
msgid "Not connected"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:6
msgid "Password protect"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:7
msgid "Allow editing"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:8
#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:9
msgid "Expires on"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:10
msgid "Link settings"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:11
msgid "Update quota"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:12
msgid "Create new folder"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:13
msgid "New folder name"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:14
msgid "Cancel"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:15
#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:24
msgid "Ok"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:16
msgid "Deselect all"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:17
msgid "Select all"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:18
msgid "Select one or multiple files"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:19
msgid "Select a file"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:20
msgid "Save to"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:21
#, javascript-format
msgid "{size} used ({percent}% of {total})"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:22
msgid "{size} used"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:23
msgid "invalid quota"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:26
msgid "{nbSelected} selected"
msgid_plural "{nbSelected} selected"
msgstr[0] ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:28
msgid "Get files path"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:29
msgid "Get files link"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:30
msgid "Download files"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:31
msgid "Get save file path"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:32
msgid "Get file upload link"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:33
msgid "Upload files"
msgstr ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:27
msgid "Upload {nbToUpload} file to {path}"
msgid_plural "Upload {nbToUpload} files to {path}"
msgstr[0] ""

#: /datadisk/tera/julien/html/nextcloud-webdav-filepicker/specialVueFakeDummyForL10nScript.js:25
msgid "Save to {path}"
msgstr ""

`,
}
